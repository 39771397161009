.title {
    font-weight: 400;
    font-size: 24px;
    line-height: 1em;
    margin: 0 0 25px;

    @include media-query(from tablet) {
        font-size: 42px;
    }

    &--main {
        display: block;
        font-size: 24px;
        font-weight: 400;
        line-height: 56px;
        text-transform: uppercase;
        text-align: left;
        margin: 0 0 30px;

        @include media-query(from tablet) {
            font-size: 48px;
        }
    }

    &--center {
        text-align: center;
    }

    &--section {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-transform: none;

        @include media-query(from laptop) {
            text-transform: uppercase;
            font-size: 36px;
            line-height: 44px;
        }
    }

    &--upper {
        text-transform: uppercase;
    }

    &--medium {
        font-size: 20px;
        font-weight: 700;
        margin: 60px 0 0;
    }
}

