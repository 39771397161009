.select2-container--default {
  &.select2-container--open {
    .select2-dropdown {
      border-radius: 0;
    }

    .select2-dropdown--above {
      border-bottom: 1px solid #cecccc;
    }
  }

  .variations & {
    text-transform: lowercase;
  }
}

.woocommerce-error {
  background: #ff0000;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  padding: 9px 10px 9px 32px;
}

.woocommerce-info {
  /*    background: #03CE33;
    color: #fff;*/
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  padding: 9px 10px 9px 0;
}

.woocommerce {
  .woocommerce-cart &,
  .woocommerce-checkout & {
    @extend .container;
    /* padding-top: 70px;*/
    overflow: hidden;
  }
  .woocommerce-order-received & {
    padding-top: 0;
  }

  .select2-container--default {
    height: 33px;

    .select2-selection--single {
      background: none;
      border: none;
      border-bottom: 1px solid #cecccc;
      border-radius: 0;
      height: 33px;
    }

    .select2-selection--single .select2-selection__rendered {
      color: #090102;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      padding: 0 0 6px;
    }
  }

  .woocommerce-cart-form {
    position: relative;

    padding: 0px 0px 40px 0;

    @include media-query(from laptop) {
      float: left;
      width: 62.5%;
      padding: 40px 40px 40px 0;
    }

    .shop_table {
      width: 100%;
      border-collapse: collapse;

      thead {
        display: none;
        text-transform: lowercase;

        @include media-query(from tablet7) {
          display: table-header-group;
        }
      }

      th {
        padding: 0 0 10px;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        border-bottom: 2px solid #d8d8d8;
      }

      tr {
        border-top: 1px solid #d8d8d8;

        &:first-child {
          border-top: none;
        }
      }

      td {
        padding: 10px 0px;
        font-weight: 300;
        font-size: 16px;
        position: relative;
        display: block;
        text-align: right;

        @include media-query(from tablet7) {
          padding: 20px 0px;
          font-size: 18px;
          display: table-cell;
          text-align: left;
          border-bottom: 1px solid #d8d8d8;
        }
        &.product-thumbnail {
          padding-top: 20px;
        }

        &:last-child {
          padding-bottom: 20px;
        }

        &:before {
          content: attr(data-title);
          position: absolute;
          left: 0;
          font-weight: bold;

          @include media-query(from tablet7) {
            content: none;
            display: none;
          }
        }
      }

      .actions {
        border-bottom: none;
        border-top: 2px solid #d8d8d8;
      }
      .product-remove {
        float: right;
        @include media-query(from tablet7) {
          float: none;
        }

        .remove {
          font-size: 35px;
          display: block;
          width: auto;
          height: auto;
          line-height: 1em;
          font-weight: 400;
          text-indent: 0;
          padding: 0 10px;
          margin-top: 43px;

          @include media-query(from tablet7) {
            margin-top: 0;
          }

          &:before {
            content: none;
          }
        }
      }
      .product-thumbnail {
        float: left;
        @include media-query(from tablet7) {
          float: none;
        }
      }
      .product-name {
        clear: both;

        @include media-query(from tablet7) {
          clear: none;
        }
      }

      .product-name,
      .product-price,
      .product-subtotal {
        text-align: right;

        @include media-query(from tablet7) {
          text-align: left;
        }
      }

      .product-quantity {
        text-align: right;

        @include media-query(from tablet7) {
          text-align: center;
        }
      }

      button[name="update_cart"] {
        float: right;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        border: none;
        border-bottom: 2px solid #090102;
        text-transform: lowercase;
      }

      .coupon {
        display: block;
        padding: 0 0 20px;
        margin: 0 0 20px;
        border-bottom: 1px solid #d8d8d8;

        label {
          display: inline-block;
          padding: 0 10px 0 0;
        }

        #coupon_code {
          padding: 0;
          margin: 0 10px 0 0;
          font-size: 16px;
          line-height: 24px;
          border: none;
          border-bottom: 1px solid #090102;
          text-align: left;
        }

        input[name="apply_coupon"] {
          padding: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          border: none;
          border-bottom: 1px solid #090102;
        }
      }
    }

    .button--increase,
    .button--decrease {
      display: none;
    }

    .product-thumbnail {
      img {
        width: 80px;
        height: auto;
      }
    }

    .quantity {
      label {
        display: none;
      }

      input {
        width: 73px;
        border: none;
        border-bottom: 1px solid #090102;
        text-align: center;
      }
    }
  }

  .cart-collaterals {
    @include media-query(from laptop) {
      float: left;
      width: 37.5%;
    }
  }

  #order_review,
  .cart_totals {
    background: #f4f7fc;
    padding: 40px;

    h2 {
      padding: 0 0 10px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-transform: uppercase;
      border-bottom: 2px solid #090102;
    }

    table {
      width: 100%;
      table-layout: fixed;
    }

    th {
      width: 40%;
      padding-right: 40px;
      vertical-align: top;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      text-align: left;
    }

    td {
      font-weight: 300;
      font-size: 18px;
      line-height: 26px;
    }

    .cart-subtotal td,
    .cart-subtotal td {
      padding-bottom: 20px;
    }

    #shipping_method {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: block;
        padding-top: 20px;

        &:first-child {
          padding-top: 0;
        }
      }

      .amount {
      }

      label {
        position: relative;
        display: block;
        padding: 0 0 0 34px;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
      }

      input {
        position: absolute;
        opacity: 0;
      }

      label:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        width: 18px;
        height: 18px;
        border: 1px solid #090102;
        vertical-align: top;
        transform: rotate(-45deg);
      }

      input:checked + label:after {
        position: absolute;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        left: 3px;
        top: 6px;
        background: #090102;
        transform: rotate(-45deg);
      }
    }

    .woocommerce-shipping-calculator {
      padding: 0 0 40px;

      p {
        margin: 0;
        padding: 0;
      }

      .shipping-calculator-button {
        display: none;
      }

      .shipping-calculator-form {
        display: block !important;
        padding: 20px 0 0;

        .form-row,
        button {
          display: none !important;
        }

        #calc_shipping_country_field {
          display: block !important;
        }
      }
    }

    .order-total {
      td,
      th {
        border-top: 2px solid #090102;
      }

      th {
        padding-top: 20px;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-transform: uppercase;
        text-align: left;
        vertical-align: top;
      }

      td {
        padding-top: 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        vertical-align: top;
      }
    }

    .wc-proceed-to-checkout {
      padding: 40px 0 0;

      .checkout-button {
        @extend .button--block;

        &:after {
          content: none;
        }
      }
    }

    .shop_table .cart-subtotal th {
      text-transform: lowercase;
    }

    .shop_table .shipping th {
      text-transform: lowercase;
    }
  }

  #order_review_heading {
    position: relative;
    margin: 60px 0 0;
    padding: 0 40px 10px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    background: #f4f7fc;

    @include media-query(from laptop) {
      margin: 0;
      float: right;
      width: calc(37.5% - 10px);
    }

    &:before {
      content: "";
      display: block;
      height: 42px;
      position: absolute;
      left: 0;
      top: -36px;
      width: 100%;
      background: url(../images/gph-check-blue.svg) repeat-x 0 100%;
    }

    &:after {
      content: "";
      display: block;
      margin: 10px 0 0;
      height: 2px;
      background: #d8d8d8;
    }
  }

  #customer_details {
    position: relative;

    @include media-query(from laptop) {
      float: left;
      width: calc(62.5% - 10px);
      margin-right: 10px;
    }

    .col-1,
    .col-2 {
      flex: none;
      max-width: 100%;
    }

    .woocommerce-shipping-fields__field-wrapper,
    .woocommerce-billing-fields__field-wrapper {
      padding-top: 20px;
    }

    h3 {
      margin: 0;
      padding: 0 0 10px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-transform: uppercase;
      border-bottom: 2px solid #d8d8d8;
    }

    .form-row {
      position: relative;
      margin: 0 0 20px;
      padding-top: 18px;
    }

    .required {
      text-decoration: none;
    }

    label {
      position: absolute;
      left: 1px;
      top: 19px;
      font-weight: 300;
      font-size: 18px;
      line-height: 26px;
      transition: font-size 300ms, top 300ms;

      &.checkbox {
        position: relative;
        left: 0;
        top: 0;
      }

      &[for="billing_country"],
      &[for="shipping_country"],
      &[for="order_comments"],
      &[for="billing_address_1"],
      &[for="shipping_address_1"],
      &.is-focused {
        top: 0px;
        font-size: 12px;
        line-height: 20px;
      }
    }

    textarea,
    input {
      width: 100%;
      border: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      padding: 0 0 6px;
      border-bottom: 1px solid #cecccc;
    }

    textarea {
      height: 100px;
      resize: vertical;
    }

    #billing_address_2_field {
      padding-top: 0;
    }

    #billing_first_name_field,
    #billing_company_field {
      float: left;
      width: calc(50% - 10px);
      margin-right: 10px;
    }

    #billing_state_field,
    #billing_last_name_field,
    #billing_country_field {
      float: left;
      width: calc(50% - 10px);
      margin-left: 10px;
    }

    #billing_city_field {
      float: left;
      width: calc(50% - 10px);
      margin-right: 10px;
    }
    #billing_postcode_field {
      float: right;
      width: calc(50% - 10px);
      margin-left: 10px;
    }

    #billing_address_1_field,
    #billing_phone_field {
      clear: both;
    }

    #ship-to-different-address {
      overflow: hidden;
      position: relative;

      input {
        border: none;
        width: 10px;
        height: 10px;
        position: absolute;
        left: -999px;
      }

      span {
        display: block;
        margin: 0;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        padding: 0 0 0 36px;
      }

      input + span:before {
        content: "";
        display: block;
        position: absolute;
        left: 4px;
        top: 9px;
        width: 18px;
        height: 18px;
        border: 1px solid #090102;
        vertical-align: top;
        transform: rotate(-45deg);
      }

      input:checked + span:after {
        position: absolute;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        left: 7px;
        top: 12px;
        background: #090102;
        transform: rotate(-45deg);
      }
    }

    .woocommerce-shipping-fields {
      padding: 60px 0 0;
    }

    .woocommerce-additional-fields {
      padding: 22px 0 0;
    }
  }

  .woocommerce-info {
    font-size: 14px;
  }

  .checkout_coupon {
    overflow: hidden;
    .form-row {
      float: left;
    }

    input {
      border: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 0 10px 4px 0;
      border-bottom: 1px solid #cecccc;
    }

    button {
      padding: 0 0 4px 0;
      margin: 0 0 0 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      border: none;
      border-bottom: 1px solid #090102;
    }
  }

  #order_review {
    position: relative;
    background: #f4f7fc;
    margin-bottom: 40px;

    @include media-query(from laptop) {
      float: left;
      width: calc(37.5% - 10px);
      padding: 0px 40px 40px;
      margin-left: 10px;
      margin-bottom: 40px;
    }

    &:after {
      content: "";
      display: block;
      height: 42px;
      position: absolute;
      left: 0;
      bottom: -36px;
      width: 100%;
      background: url(../images/gph-check-blue-bottom.svg) repeat-x 0 0;
      background-size: 445px 42px;
    }

    .blockUI {
      top: -95px !important;
      bottom: -80px !important;
      left: -40px !important;
      right: -40px !important;
      width: auto !important;
      height: auto !important;
    }

    .select2-container--default .select2-selection--single {
      background: none;
    }

    .shop_table {
      border-collapse: collapse;
      border-spacing: 0;

      th {
        padding-right: 40px;
        width: auto;
      }

      td,
      th {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .product-quantity {
        white-space: nowrap;
      }

      label:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        width: 18px;
        height: 18px;
        border: 1px solid #090102;
        vertical-align: top;
        transform: rotate(-45deg);
      }

      input:checked + label:after {
        position: absolute;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        left: 3px;
        top: 6px;
        background: #090102;
        transform: rotate(-45deg);
      }

      .cart-subtotal th,
      .cart-subtotal td {
        padding-bottom: 20px;
        border-top: 1px solid #d1dff2;
      }
    }

    .cart_item td {
      border-top: 1px solid #d1dff2;
      padding-right: 10px;
    }

    .shipping th,
    .shipping td {
      padding: 20px 0;
      border-top: 2px solid #090102;
    }

    .order-total {
      td {
        text-align: right;
        line-height: 20px;
        padding-top: 23px;
      }

      .includes_tax {
        font-size: 14px;
        font-weight: 300;
        white-space: nowrap;
      }
    }

    #payment {
      padding: 20px 0 0;

      .wc_payment_methods {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 20px 0;
          border-top: 1px solid #d1dff2;

          &:first-child {
            border: none;
          }
        }
      }

      .wc_payment_method {
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;

        .payment_box {
          padding: 0 0 0 34px;
        }

        label {
          display: block;
          position: relative;
          padding: 0 0 0 34px;
          font-size: 18px;
          line-height: 26px;
          font-weight: 700;

          img {
            vertical-align: top;
          }
        }
        label:before {
          content: "";
          display: block;
          position: absolute;
          left: 2px;
          top: 3px;
          width: 18px;
          height: 18px;
          border: 1px solid #090102;
          vertical-align: top;
          transform: rotate(-45deg);
        }

        input:checked + label:after {
          position: absolute;
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          left: 5px;
          top: 6px;
          background: #090102;
          transform: rotate(-45deg);
        }

        .makecommerce_country_picker_countries {
          padding: 0 0 20px;

          label:before {
            content: none !important;
          }
          label:after {
            content: none !important;
          }
          label {
            display: inline-block;
          }
        }
        .payment_method_makecommerce {
          padding: 20px 0 0 34px !important;
        }
      }

      .terms {
        label {
          display: block;
          position: relative;
          font-size: 18px;
          font-weight: 300;
          line-height: 26px;
          padding: 4px 0 0 34px;
          overflow: hidden;
        }

        a {
          text-decoration: underline;
        }

        input {
          position: absolute;
          left: -100px;
        }

        input + span:before {
          content: "";
          display: block;
          position: absolute;
          left: 4px;
          top: 9px;
          width: 18px;
          height: 18px;
          border: 1px solid #090102;
          vertical-align: top;
          transform: rotate(-45deg);
        }

        input:checked + span:after {
          position: absolute;
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          left: 7px;
          top: 12px;
          background: #090102;
          transform: rotate(-45deg);
        }
      }

      #place_order {
        @extend .button--block;
      }
    }
  }

  .woocommerce-checkout-review-order thead .product-name {
    text-transform: lowercase;
  }

  .woocommerce-checkout-review-order thead .product-total {
    text-transform: lowercase;
  }
}

.woocommerce-terms-and-conditions-wrapper input[type=checkbox] {
  -webkit-appearance: checkbox;
}
