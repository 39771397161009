.footer {
	margin: 130px 0 0;
	background: #DBE7F5;
	padding: 7px 0 20px;

	@include media-query(from tablet) {
		padding: 30px 0 56px;
	}

	@include media-query(from laptop) {
		margin: 200px 0 0;
	}

	&__contact {
		padding: 30px 0 0;
		margin: 30px 0 0;
		font-size: 16px;
		line-height: 30px;
		border-top: 1px solid #BCD1EB;

		@include media-query(from laptop) {
			border: none;
			padding: 3px 0 0;
			margin: 0;
		}

		p {
			margin: 0;
		}
	}
	
	&__copyright {
		background: #fff;
		text-align: center;
		margin: 0;
		padding: 19px 0;
		font-size: 16px;
		line-height: 1.8em;
		letter-spacing: 1px;
		font-weight: 400;
		text-transform: uppercase;
	
		@include media-query(from laptop) {
			border: none;
			margin: 0;
			padding: 19px 0;
			font-size: 18px;
		}
	}	
	&__rights {
		display: block;

		@include media-query(from tablet) {
			display: inline;
		}
	} 
}