@import "settings";
@import "tools/px-em";
@import "tools/map-reach";
@import "tools/fluid-type";
@import "tools/fluid-type-vh";
@import "tools/media-query";
@import "tools/strip-unit";
@import "tools/aspec-ratio";
@import "tools/math";
@import "tools/clearfix";

@import "reset";

@import "vendor/swiper";

@import "vendor/flag-icons/flag-icon";
@import "vendor/intlTelInput";

// Layout
@import "components/container";
@import "components/grid";

// Components
@import "components/icon";
@import "components/title";
@import "components/text";
@import "components/button";

@import "components/nav";

@import "components/header";
@import "components/footer";

@import "components/woocommerce";
//@import "components/downloads";

/*@import "vendor/slick";

@import "components/input";
@import "components/form";

@import "components/modal";
@import "components/nav-bar";
@import "components/textimage";
@import "components/imagecurve";
@import "components/tabspanel";
@import "components/image-block";
@import "components/image-grid"; // gallery-grid rename, refactor class names
@import "components/search";
@import "components/product-family";


@import "components/component";*/


.main {
  padding: 50px 0 0;

  body.admin-bar & {
    padding-top: (50px + 32px);
  }

	@include media-query(from tablet) {
		padding: 90px 0 0;

		.post-type-archive-product & {
			padding-top: 50px;
		}
	}
	/*
	&--content {
		padding-top: 68px;
	}*/
}

.cart-link {
	position: fixed;
	z-index: 10;
	top: 9px;
	right: 20px;
	width: 18px;
	height: 30px;
	background: url(../images/icon-cart.svg) no-repeat 0 0;

  body.admin-bar & {
    /*top: (9px + 32px);*/
  }

	@include media-query(from tablet) {
		top: 12px;
		right: 39px;
		width: 30px;
		height: 51px;
	}

	&__count {
		display: block;
		padding: 15px 0 0;
		text-align: center;
		font-weight: 700;
		font-size: 10px;

		@include media-query(from tablet) {
			padding: 26px 0 0;
			font-size: 16px;
		}
	}
}

.shop-link {
	display: none;
	position: fixed;
	z-index: 5;
	top: 50%;
	right: 20px;
	width: 70px;
	height: 70px;
	transform: translateY(-50%);

	@include media-query(from tablet) {
		display: block;
	}

	&:before {
		content: '';
		display: block;
		width: 50px;
		height: 50px;
		border: 2px solid #090102;
		transform: rotate(-45deg);
		margin: 10px 0 0 10px;
	}


	&__text {
		position: absolute;
		width: 100%;
		text-align: center;
		top: 26px;
		left: 0;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 15px;
		letter-spacing: 1px;
		text-align: center;
	}
}

.product-block {
	display: block;
	padding: 40px 0 0;

	&__image-wrap {
		position: relative;
		overflow: hidden;
	}

	&__hover {
		z-index: 4;
		position: absolute;
		left: 0;
		bottom: 0;
		white-space: nowrap;
		overflow: hidden;
	}

	&__hover-image {
		opacity: 0;
		z-index: 2;
		position: absolute;
		left: 0;
		top: 0;
	}

	.icon {
		height: 26px;
		opacity: 0;
		transition: opacity 300ms $ease-out-cubic;

		@for $i from 1 through 21 {
			&:nth-child(#{$i + 1}) {
				transition: opacity 300ms ($i * 20ms) $ease-out-cubic;
			}
		}
	}

	&:hover {
		.icon {
			opacity: 1;

			@for $i from 1 through 7 {
				&:nth-child($i) {
					transition: opacity 300ms ($i * 10)ms $ease-out-cubic;
				}
			}
		}

		.product-block__hover-image {
			opacity: 1;
		}
	}

	img,
	&__image {
		display: block;
		width: 100%;
		height: auto;
	}

	&__title {
		font-size: 16px;
		font-weight: bold;
		margin: 3px 0 4px;
		text-transform: none;

		@include media-query(from tablet) {
			font-size: 18px;
			margin: 13px 0 4px;
			text-transform: uppercase;
		}
	}

	&__price {
		font-size: 16px;
		font-weight: 300;
		margin: 0;

		@include media-query(from tablet) {
			font-size: 18px;
		}
	}


}

.line {
	width: 100%;
	height: 1px;
	border: none;
	background: #090102;
	margin: 40px 0 40px;

	@include media-query(from tablet) {
		margin: 100px 0;
	}
}

.product {
	padding-top: 50px;

	@include media-query(from laptop) {
		padding-top: 0px;
	}
	&__title {
		font-size: 24px;
		font-weight: 400;
		line-height: 36px;
		margin: 21px 0 0;

		position: absolute;
		top: 50px;
		z-index: 1;
		text-align: center;
		left: 0;
		width: 100%;

		@include media-query(from laptop) {
			font-size: 48px;
			position: relative;
			top: 0;
			text-align: left;
			margin: 0 0 43px;
		}
	}

	&__name {
		margin-right: 5px;

		@include media-query(from laptop) {
			display: inline-block;
			margin-right: 30px;
		}
	}

	&__price {
		font-size: 18px;
		white-space: nowrap;

		ins {
			text-decoration: none;
		}

		@include media-query(from laptop) {
			font-size: 36px;
		}
	}

	&__info {
		background: #F4F7FC;
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
		padding: 0 20px 40px;
		margin: 20px 20px 10px;
		flex: auto;

		@include media-query(from tablet) {
			margin-left: 104px;
			margin-right: 104px;
		}

		@include media-query(from laptop) {
			font-size: 18px;
			line-height: 26px;
			position: absolute;
			right: 94px;
			left: 50%;
			top: 0;
			z-index: 2;
			margin: 90px 10px 0;
			padding: 52px 77px 80px;

			&.is_stuck {
				position: fixed;

			}
		}
	}

	&__description {
		word-break: break-word;
	}

	&__options {
		margin: 35px 0 0;
		padding: 20px 0 0;
		border-top: 1px solid #090102;
	}

	&__option {
		label,
		&-title {
			display: block;
			margin: 0;
			font-size: 16px;
			font-weight: bold;

			@include media-query(from tablet) {
				font-size: 18px;
				line-height: 26px;
			}
		}

		label {
			margin-bottom: 10px;

			@include media-query(from tablet) {
				margin-bottom: 17px;
			}
		}

		&--color {
			float: left;
			width: 45%;
		}

		&--variation {
			float: right;
			width: 55%;
			font-size: 0;
			padding-bottom: 40px;

			label {
				margin-bottom: 10px;
			}
		}

		&--quantity {
			float: right;
			width: 55%;
			font-size: 0;

			.button {
				vertical-align: top;
				width: 35px;
				height: 45px;
				font-weight: 400;
				font-size: 24px;
				border: none;
				background: transparent;

				@include media-query(from tablet) {
					font-size: 36px;
					width: 45px;
				}

				&--decrease {
					margin-left: -10px;
					@include media-query(from tablet) {
						margin-left: 10px;
					}
				}
			}

			input {
				vertical-align: top;
				text-align: center;
				width: 37px;
				height: 45px;
				font-weight: 400;
				font-size: 24px;
				border: none;
				padding: 0;
				background: transparent;

				@include media-query(from tablet) {
					width: 77px;
					font-size: 36px;
				}
			}
		}

		&--add-to-cart {
			clear: both;
			padding: 45px 0 0;

			@include media-query(from tablet) {
				padding: 85px 0 0;
			}
		}
	}

	&__color {
		display: inline-block;
		width: 35px;
		height: 35px;
		border: 2px solid transparent;
		padding: 3px;
		transform: rotate(-45deg);
		margin: 15px 0 0 22px;

		@include media-query(from tablet) {
			width: 42px;
			height: 42px;
			margin: 17px 0 0 32px;
		}

		img {
			width: 100%;
			height: auto;
		}
		&.is-active {
			margin: 15px 0 0 7px;
			border: 2px solid #090102;

			@include media-query(from tablet) {
				margin: 17px 0 0 7px;
			}
		}
	}

	&__images {
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 0;
		z-index: 1;

		@include media-query(from tablet) {
			margin: 0 84px;
		}

		@include media-query(from laptop) {
			margin: 0px 0 0;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}

		.swiper-pagination-bullet {
			border: 1px solid #000 !important;
			border-radius: 0 !important;
			background: transparent;
			border-radius: none;
			transform: rotate(-45deg);
			opacity: 1;
		}

		.swiper-pagination-bullet-active {
			background: #000;
		}

		&-wrapper {
			@include media-query(to 1023px) {
				position: relative;
				width: 100%;
				height: 100%;
				z-index: 1;
				display: -ms-flexbox;
				display: flex;
				transition-property: transform;
				flex-wrap: nowrap;
			}
			@include media-query(from laptop) {
				padding-left: 94px;
			}

			.col {
				@include media-query(from laptop) {
					padding: 20px 0 0;

					&:first-child {
						padding: 0;
					}
				}
			}
		}
		.col {
			@include media-query(to 1023px) {
				width: 100%;
				align-self: center;
				/*height: 100%;	*/
			}
		}
	}
}


.list {
	&--logos {
		list-style: none;
		margin: 0;
		padding: 0;

		.list__item {
			padding: 10px 0;
		}

		.list__logo {
			@include media-query(from tablet) {
				width: 100%;
				height: auto;
			}

			&--paypal {
				@include media-query(from tablet) {
					max-width: 110px;
				}
			}
			&--visa {
				@include media-query(from tablet) {
					max-width: 63px;
				}
			}
			&--mastercard {
				@include media-query(from tablet) {
					max-width: 43px;
				}
			}
			&--incubator {
				@include media-query(from tablet) {
					max-width: 290px;
				}
			}
			&--support {
				@include media-query(from tablet) {
					max-width: 124px;
				}
			}
		}
	}
}

.hero {
	padding: 0px 0 50px;

	@include media-query(from tablet) {
		margin: -20px 0 0;
		padding: 0 0 70px;
	}

	&__image {
		position: relative;
		height: calc(100vh - 100px);
		max-height: 468px;
		background-image: url(../images/_example/front_img.png);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;

		@include media-query(from tablet) {
			max-height: none;
			height: calc(100vh - 140px);
		}
	}

	&__image-hover {
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: url(../images/_example/front_img.png);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		transition: opacity 300ms $ease-out-cubic;

		&.is-visible {
			opacity: 1;
		}
	}

	&__text {
		position: absolute;
		z-index: 3;
		font-size: 32px;
		top: 50%;
		transform: translateY(-50%);
		left: 9%;
		width: 90%;
		text-shadow: -2px 0px 0px #F0F3F6;
		@include fluid-type(font-size, 768px, 1500px, 32px, 69px);
		
		@include media-query(from largephone) {
			width: 60%;
		}

		@include media-query(from laptop) {
			width: 55%;
		}

		@include media-query(from desktop) {
			width: 42%;
		}
	}

	&__more {
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 24px;
		text-transform: none;
		padding-bottom: 4px;
		padding-top: 8px;
		letter-spacing: 0;

		@include media-query(from laptop) {
			bottom: 80px;
		}
	}

	&__eu {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 86px;
		padding: 7px 8px;
		background: #ffffff;

		@include media-query(from laptop) {
			width: 144px;
			padding: 12px 15px;
		}

		img {
			display: block;
			height: auto;
		}
	}

	@keyframes fade-in-out {
		0% {opacity:0;}
		50% {opacity:1;}
		100% {opacity: 0;}
	}

	&__arrows {
		position: absolute;
		left: 50%;
		margin-left: -16px;
		bottom: -27px;

		.icon {
			display: block;

			&:nth-child(1) {
				opacity: .2;
				animation: fade-in-out;
				animation-duration: 2000ms;
				animation-delay: 0ms;
				animation-iteration-count: infinite;
			}

			&:nth-child(2) {
				opacity: .4;
				margin-top: -3px;
				animation: fade-in-out;
				animation-duration: 2000ms;
				animation-delay: 200ms;
				animation-iteration-count: infinite;
			}

			&:nth-child(3) {
				opacity: .6;
				margin-top: -3px;
				animation: fade-in-out;
				animation-duration: 2000ms;
				animation-delay: 400ms;
				animation-iteration-count: infinite;
			}

			&:nth-child(4) {
				margin-top: -3px;
				animation: fade-in-out;
				animation-duration: 2000ms;
				animation-delay: 600ms;
				animation-iteration-count: infinite;
			}
		}
	}

	&__text-top,
	&__text-middle {
		position: absolute;
		top: 50px;
		left: 5%;
		right: 5%;
		text-align: center;

		h1 {
			text-align: center;
			margin: 0 0 20px;
			line-height: 1.2em;
		}

		p {
			margin: 0 0 20px;
			font-weight: 300;
			font-size: 24px;
			line-height: 36px;
		}
	}

	&__text-middle {
		top: 50%;
		transform: translateY(-50%);

		@include media-query(from laptop) {
			h1 {
				text-align: left;
			}

			left: 15%;
			text-align: left;
		}
		@include media-query(from desktop) {
			left: 25%;
		}
	}

	&__list-bottom {
		position: absolute;
		bottom: 40px;
		left: 0;
		right: 0;
		text-align: center;
		font-size: 0;
		list-style: none;
		margin: 0;
		padding: 0;

		h3 {
			display: inline-block;
			margin: 0 5px;
			font-size: 18px;
			line-height: 26px;
		}

		li {
			display: inline-block;
			white-space: nowrap;
			padding: 0 0 0 35px;

			&:first-child {
				padding-left: 0;
			}
		}

		p {
			display: inline-block;
			margin: 0 5px;
			font-size: 18px;
			line-height: 26px;
			font-weight: 300;
		}
	}
}

.sizes {
	position: relative;
	background: #f1f1f2;

	.title--section {
		position: absolute;
		z-index: 3;
		top: 125px;
	}

	&__size {
		position: relative;
		padding: 205px 10px 0;
		height: 100%;

		&:hover .sizes__specs {
			display: block;
		}
	}

	&__specs {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 205px 10px 0;
		background: #fab;

		@include media-query(to tablet) {
			&.is-visible {
				display: block;
				position: fixed;
				padding: 60px 10px 60px;
				z-index: 40;
				overflow-y: auto;
			}
		}
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;

		.icon {
			width: 15px;
			height: 15px;
		}
	}

	&__image {
		height: 280px;
		margin: 0 0 60px;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
	}

	&__title {
		margin: 0;
		font-size: 20px;
		font-weight: 700;
	}

	&__text {
		font-size: 16px;
		line-height: 30px;
	}
}

.overlay-block {
	padding-top: 190px;
	position: relative;
	height: 100%;

	&__overlay {
		display: none;
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		padding-top: 190px;
		background: #fff;

		&.is-visible {
			display: block;
		}

		@include media-query(from tablet) {
			.row {
				margin: 0;
			}
		}
	}

	&:hover &__overlay {
		display: block;
	}

	&__close {
		position: absolute;
		right: 20px;
		top: 274px;
		font-size: 14px;

		.icon {
			width: 20px;
			height: 20px;
			margin: 0 0 0 5px;
		}

		@include media-query(from tablet) {
			display: none;
		}
	}

	&__specs {
		display: inline-block;
		top: -3px;
		position: relative;
		margin: 0 0 10px;
		font-weight: 400 !important;

		@include media-query(from tablet) {
			display: none;
		}
	}
}

.sticky-buttons {
	font-size: 0;
	position: fixed;
	z-index: 10;
	right: 0;
	bottom: 0;
	list-style: none;
	padding: 0;
	margin: 0;

	&__item {
		display: inline-block;
		vertical-align: bottom;
	}

	&__image {
		display: none;
		margin: 0 auto 35px;

		@include media-query(from tablet) {
			display: block;
		}
	}

	&__link {
		display: inline-block;
		position: relative;
		z-index: 2;
		font-size: 16px;
		width: 160px;
		text-align: center;
		height: 64px;
		line-height: 64px;
		background: #fff;
		box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.22);

		@include media-query(from tablet) {
			width: 170px;
		}

		sup {
			position: absolute;
			top: -5px;
		}

		&--dark {
			z-index: 1;
			color: #fff;
			background: #221f20;
		}
	}
}

.content {
	padding: 10px 0;

	@include media-query(from laptop) {


		&:first-child {
			padding-top: 0;

			.title--main {
				padding-top: 0;
			}
		}
	}

	.row {
		position: relative;
	}

	&__image {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #b1c4d4;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 60% auto;

		img {
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 80%;
			margin: 0 auto;

			@include media-query(from tablet) {
				width: 60%;
			}
		}

		&--cover {
			background-size: cover;
		}

		&--video {
			overflow: hidden;

			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;';
			}
		}

		&--column {
			position: relative;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-color: transparent;
			height: 220px;
			margin: 20px 0 15px;
		}
	}

	&__text {
/*		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
*/
		&--overlay {
			position: absolute;
			left: 30px;
			top: 0px;
			z-index: 2;

			@include media-query(from tablet) {
				left: 0;
				right: 0;
			}
		}

		&--overlay-bottom {
			position: absolute;
			left: 30px;
			bottom: 0px;
			z-index: 2;

			@include media-query(from tablet) {
				left: 0;
				right: 0;
			}
		}


/*
		a {
			font-weight: 700;
			text-decoration: underline;
		}

		h1,h2,h3,h4,h5,h6,p,ul,ol {
			margin: 0;
			padding: 15px 0;
		}

		ul, ol {
			padding-left: 20px;
		}

		h1 + p,
		h2 + p,
		h3 + p,
		h4 + p,
		h5 + p,
		h6 + p {
			padding-top: 0;
		}

		h1 + .content__actions,
		h2 + .content__actions,
		h3 + .content__actions,
		h4 + .content__actions,
		h5 + .content__actions,
		h6 + .content__actions,
		p + .content__actions {
			padding-top: 0;
		}*/
	}

	&__height {
		/*
		0.33 1220x400 (280x280/1)
		0.66 600x400 (280x280/1)
		0.72 833x600 (280x202/0.72)
		1.04 368 x 400 (280x304/1.08)
		1.35 445 x 600 (280x378/1.35)
		1.63 523x853 (280x457/1.63)
		1.63 368x600 (280x457/1.63)
		*/
		position: relative;

		&--33 {
			padding-bottom: 100%;

			@include media-query(from largephone) {
				padding-bottom: 33%;
			}
		}

		&--66 {
			padding-bottom: 100%;

			@include media-query(from largephone) {
				padding-bottom: 66%;
			}
		}

		&--72 {
			padding-bottom: 72%;
			//padding-top: 20px;

		}

		&--104 {
			padding-bottom: 108%;

			@include media-query(from largephone) {
				padding-bottom: 104%;
			}
		}

		&--135 {
			padding-bottom: 135%;
		}

		&--163 {
			padding-bottom: 163%;
		}
	}

	/*&--process {
		.content__height {
			position: relative;
			height: 280px;

			@include media-query(from largephone) {
				height: auto;
				padding-bottom: 66%;
			}
		}

		.content__text {
			padding: 25px 0;

			@include media-query(from laptop) {
				padding: 0;
			}

			&--overlay {
				padding: 0;
			}
		}

		.row {
			@include media-query(from laptop) {
				padding: 10px 0;
			}
		}
	}*/

	&--products {
		position: relative;
		padding-top: 10px;

		@include media-query(from tablet) {
			padding-top: 40px;
		}

		.title--main {
			position: absolute;
			z-index: 5;
			top: 70px;
			left: 50%;
			margin: 0;
			transform: translateX(-50%);

			@include media-query(from tablet) {
				top: 110px;
			}
		}
	}

	&--featured {
		margin: -40px 0 40px;

		@include media-query(from laptop) {
			padding: 0;
		}

		.title {
			position: absolute;
			z-index: 3;
			padding: 50px 10px 0px;

			@include media-query(from tablet) {
				padding: 85px 40px 0px;
			}
		}
		.text {
			padding: 43px 0 0;
			@include media-query(from laptop) {
				padding: 73px 0 0;
			}
		}
	}

	&--process,
	&--3,
	&--3-text,
	&--single,
	&--horizontal-2,
	&--vertical-2 {
		.text {
			padding: 0 10px 40px;

			@include media-query(from tablet) {
				padding: 0 20px 40px;
			}

			@include media-query(from laptop) {
				padding: 0 40px 40px;
			}
		}

		.content__text {
			position: relative;
			left: auto;
			bottom: auto;
			margin-top: -58px;
			padding: 0;

			&--about-top {
				position: absolute;
				margin-top: 0;
				left: 0;
				top: 0;
				right: 0;
			}

			&--about-bottom {
				margin-top: 0;
				right: 0;
			}

			&--about-top,
			&--overlay {
				@include media-query(from tablet) {
					position: absolute;
					left: 0;
					top: 28px;
					z-index: 2;
					margin-top: 0;
				}
			}

			&--about-bottom,
			&--overlay-bottom {
				@include media-query(from tablet) {
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 2;
					margin-top: 0;
				}
			}
		}

		.content__height--pushed  {
			margin: 0;

			@include media-query(from tablet) {
				margin-bottom: 57%;
			}
		}
	}

	&--3-text,
	&--process,
	&--3,
	&--single,
	&--horizontal-2,
	&--vertical-2 {
		.col--last {
			padding-top: 20px;
		}
		.row--vertical-center {
			display: block;

			@include media-query(from tablet) {
				display: flex;
			}
		}
	}

	&--horizontal-2 {
		.content__text + .col--vertical-bottom {
			margin-top: 0;
		}
		.col--vertical-bottom + .col--vertical-bottom {
			margin-top: 20px;
		}
	}

	&--vertical-2 {
		.content__text + .col--vertical-bottom {
			margin-top: 0;
		}
		.col--vertical-bottom + .col--vertical-bottom {
			margin-top: 20px;
		}
	}

	&--3 {
		.col {
			padding-top: 20px;

			@include media-query(from tablet) {
				padding-top: 0;
			}

			&:first-child {
				padding-top: 0;
			}


		}
	}

	&--process {
		.text {
			padding: 0 10px 40px;

			@include media-query(from tablet) {
				padding: 0 20px 0px;
			}

			@include media-query(from laptop) {
				padding: 0 40px 0px;
			}
		}

		.content__height {
			@include media-query(from tablet) {
				margin: 10px 0;
			}
		}
	}

	&--vertical-2 {
		.text {
			@include media-query(from tablet) {
				margin-bottom: 57%;
			}
		}
	}
}

.contact {
	margin: 0;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;

	@include media-query(from tablet) {
		margin: 0;
		font-size: 18px;
	}

	a {
		text-decoration: underline;
	}

	&__bg {
		background: #F4F7FC;
		padding: 0 10px;

		@include media-query(from tablet7) {
			padding: 0 10px 20px;
		}

		@include media-query(from tablet) {
			padding: 0 10px 40px;
		}
	}

	&__address {
		padding-bottom: 22px;
	}

	.row--wide {
		padding: 0 10px;
	}

	.title--main {
		margin: 18px 0 0;
		text-align: center;

		@include media-query(from tablet7) {
			margin: 18px 0 20px;
		}

		@include media-query(from tablet) {
			margin: 38px 0 40px;
		}
	}

	&__subtitle {
		margin: 20px 0 5px;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;

		&--address {
			@include media-query(from tablet7) {
				margin: 0 0 5px;
			}
		}

		@include media-query(from tablet) {
			font-size: 18px;
		}
	}

	p {
		margin: 0;
	}

	&__col {
		&--info {
			position: relative;
			&:after {
				content: '';
				display: block;
				height: 1px;
				background: #090102;
			}

			@include media-query(from tablet7) {
				border-right: 1px solid #090102;
				padding-right: 20px;
				padding-bottom: 80px;

				&:after {
					content: none;
				}
			}
		}

		&--form {

		}
	}

	&__social {
		list-style: none;
		margin: 0;
		padding: 20px 0 40px;
		font-size: 0;

		@include media-query(from tablet7) {
			position: absolute;
			left: 10px;
			bottom: 0;
			padding: 20px 0 0;
		}

		@include media-query(from laptop) {
			left: calc(12.5% + 10px);
		}

		li {
			display: inline-block;
			padding: 0 10px 0 0;
		}
	}

	.form,
	.form-success {
		padding: 20px 0 40px;

		@include media-query(from tablet7) {
			padding: 0 0 0px 10px;
		}

		@include media-query(from laptop) {
			padding: 0 20px 0 30px;
		}
	}
}

.form-success {
	display: none;

	&.is-visible {
		display: block;
	}
}
.form {

	.error {

		span.error {
			color: #ff0000;
			font-size: 14px;
		}

		input, textarea {
			border-color: #ff0000;
		}
	}

	&.is-hidden {
		display: none;
	}

	&__row {
		position: relative;
		margin: 0 0 20px;
		padding-top: 18px;

		&--textarea {
			min-height: 124px;
		}
	}

	label {
		position: absolute;
		left: 1px;
		top: 19px;
		font-weight: 300;
		font-size: 18px;
		line-height: 26px;
		transition: font-size 300ms, top 300ms;

		&.checkbox {
			position: relative;
			left: 0;
			top: 0;
		}

		&.is-focused {
			top: 0px;
			font-size: 12px;
			line-height: 20px;
		}
	}

	textarea,
	input {
		width: 100%;
		border: none;
		font-weight: 400;
		font-size: 16px;
		line-height: 26px;
		padding: 0 0 6px;
		background: none;
		border-bottom: 1px solid #CECCCC;

		@include media-query(from tablet) {
			font-size: 18px;
		}
	}
	textarea {
		resize: vertical;
		transition: height 300ms;
		height: 32px;
	}

	.is-focused + textarea {
		height: 100px;
	}
}

.hide-from-largephone {
	display: block;

	@include media-query(from largephone) {
		display: none;
	}
}
.show-from-largephone {
	display: none;

	@include media-query(from largephone) {
		display: block;
	}
}

.hide-from-tablet {
	display: block;

	@include media-query(from tablet) {
		display: none;
	}
}
.show-from-tablet {
	display: none;

	@include media-query(from tablet) {
		display: block;
	}
}

.hide-from-laptop {
	display: block;

	@include media-query(from laptop) {
		display: none;
	}
}
.show-from-laptop {
	display: none;

	@include media-query(from laptop) {
		display: block;
	}
}



























.contacts {
	&__title {
		margin: 47px 0 47px;
		font-size: 20px;
		font-weight: bold;
	}

	&__country {
		display: inline-block;
		font-size: 16px;
		padding: 5px 0;
		text-decoration: underline;

		&.is-selected {
			font-weight: bold;
			text-decoration: none;
		}
	}

	&__list {
		display: none;
		margin: 22px 0 0;
		padding: 53px 0 49px;
		font-size: 16px;
		line-height: 30px;
		border-top: 1px solid #d1d3d4;
		border-bottom: 1px solid #d1d3d4;

		&.is-visible {
			display: flex;
			width: 100%;
		}
	}

	&__company {
		margin: 0 0 15px;
		font-size: 20px;
		font-weight: bold;
	}

	&__data {
		margin: 0;
	}
}

.swiper-container--options {
	background: #cccccc;
	position: fixed;
	z-index: 15;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: visible;

	@include media-query(from tablet) {
		padding: 0 9px;
		left: 40px;
		right: 40px;
	}
}
.swiper-wrapper {
	padding: 0px 0 50px;

	@include media-query(from tablet) {
		padding: 0px 0 75px;
	}
}

.swiper-container-horizontal > .swiper-scrollbar {
	bottom: 30px;

	@include media-query(from tablet) {
		bottom: 55px;
	}
}
.swiper-scrollbar {
	background: none;

	.swiper-scrollbar-drag {
		height: 2px;
		background: #fff;
	}
}


.option {
	@extend .swiper-slide;

	display: block;
	width: 280px;
	height: 80px;
	position: relative;

	&.is-disabled {
		opacity: 0.4;
	}

	&__wrap {
		position: absolute;
		overflow: hidden;
		bottom: 0;
		width: 280px;
		height: 80px;
		background: #fff;
		transition: box-shadow 300ms, height 490ms $ease-out-cubic;

		@include media-query(from tablet) {
			&:hover {
				box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.22);
			}
		}

		.swiper-slide-active & {
			box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.22);

			@include media-query(from tablet) {
				box-shadow: none;
			}
		}

		&.is-open {
			max-height: calc(100vh - 100px);
			height: 460px;
			box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.22);
		}
	}

	&__item {
		display: flex;
		align-items: center;
		height: 80px;
		position: absolute;
		width: 100%;
		bottom: 0;
		opacity: 1;
		transition: opacity 300ms $ease-in-out-quad;

		.is-open & {
			opacity: 0;
		}
	}

	&__title {
		margin: 0 12px 0 24px;
		font-size: 16px;
		font-weight: 700;
		margin-right: auto;
	}

	&__value {
		margin: 0 12px 0;
		font-size: 14px;
		font-weight: bold;
		color: #a8baca;
		overflow: hidden;
		text-overflow: ellipsis;

		strong {
			color: #000000;
		}

		&:last-child {
			margin-right: 24px;
		}
	}

	&__icon {
		margin: 0 24px 0 0;

		&--image {
			width: 74px;
			height: 74px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: 50% 50%;
		}

		&--color {
			width: 35px;
			height: 35px;
		}

		&--flag {
			width: 20px;
		}
	}
}

.options {
	width: 280px;
	position: absolute;
	overflow: hidden;
	bottom: 0;
	height: 0;
	box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.22);

	.is-open & {
		height: 100%;
	}

	&__header {
		opacity: 0;
		height: 49px;
		line-height: 49px;
		padding: 0 0 0 30px;
		font-size: 16px;
		font-weight: 700;
		border-bottom: 2px solid #f1f1f2;
		transition: opacity 750ms 100ms $ease-out-cubic;

		.is-open & {
			opacity: 1;
		}
	}

	&__close {
		display: block;
		position: absolute;
		top: 10px;
		right: 20px;
		width: 34px;
		height: 34px;
		white-space: nowrap;
		overflow: hidden;
		text-indent: 100%;
		background: url(../images/icon-close.svg) no-repeat 10px 10px;
		background-size: 14px 14px;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		height: calc(100% - 51px);
		overflow: auto;
		opacity: 0;
		transform: translateY(330px);
		transition: transform 750ms $ease-out-cubic, opacity 750ms $ease-out-cubic;

		.is-open & {
			max-height: calc(100vh - 151px);
			height: 409px; // 460-51px
			opacity: 1;
			transform: translateY(0);
			transition: transform 750ms 100ms $ease-out-cubic, opacity 750ms 100ms $ease-out-cubic;
		}
	}

	&__categories {
		list-style: none;
		margin: 0;
		padding: 0 30px;
		font-size: 14px;

		&:last-child {
			padding-bottom: 20px;
		}
	}

	&__category {
		padding: 8px 0;
		color: #918f8f;
	}

	&__items {
		list-style: none;
		margin: 0;
		padding: 6px 0 0;
	}

	&__item {
		position: relative;
		padding: 0 0 15px;
		border-bottom: 2px solid #f1f1f2;

		&:last-child {
			border-bottom: none;
		}

		&.is-selected {
			&:before {
				content: '';
				float: left;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				display: block;
				vertical-align: top;
				margin: 21px 10px 0 0;
				background: #f1f1f2;
			}
		}

		&--color {
			height: 50px;
			padding: 0 30px;
			border: none;
		}

		&--list {
			position: relative;
			padding: 10px 0 10px 18px;
			border: none;

			&.is-selected {
				color: #000;

				&:before {
					margin: 0;
					position: absolute;
					left: 0;
					top: 14px;
					background: #656262;
				}
			}
		}
	}

	&__section-title {
		margin: 10px 0 15px;
		color: #221f20;
		font-size: 16px;
		font-weight: 400;
		padding: 12px 30px 0;

		.options__description + & {
			margin-top: -4px;
		}
	}

	&__category-title {
		font-size: 14px;
		font-weight: 400;
		margin: 0;
		overflow: hidden;
		padding: 0;

		&.is-selected {
			color: #000;
			font-weight: bold;
		}

		.flag-icon {
			float: right;
			width: 27px;
			height: 20px;
			margin-top: 2px;
		}
	}

	&__title {
		margin: 17px 30px 20px;
		text-align: left;
		font-size: 14px;
		font-weight: 700;
		color: #221f20;

		&--color {
			text-align: right;
			margin: 0;
			color: #fff;
			line-height: 50px;
			opacity: .5;
			white-space: nowrap;

			&:first-child {
				float: left;
			}
		}

		&--color-dark {
			text-align: right;
			margin: 0;
			color: #000;
			line-height: 50px;
			opacity: .5;
			white-space: nowrap;

			&:first-child {
				float: left;
			}
		}

		&--input {
			text-align: right;
			margin: 0;
			color: #000;
			line-height: 50px;
			white-space: nowrap;

			&:first-child {
				float: left;
			}
		}
	}

	&__input {
		display: inline-block;
		width: 40px;
		height: 30px;
		padding: 0 10px;
		font-size: 14px;
		background: #fff;
		color: #000;
		border: 1px solid #d1d3d4;
	}

	&__info {
		display: block;
		min-height: 16px;
		margin: 5px 30px 0px;
		text-align: left;
		font-size: 14px;
		font-weight: 700;
		color: #221f20;

		a {
			float: right;
			font-weight: 400;
			color: #918f8f;
		}
	}

	&__description {
		padding: 5px 30px;
		font-size: 12px;
		color: #363636;
		line-height: 1.667em;
	}

	&__text {
		text-align: center;
		font-size: 14px;

		p {
			margin: 10px 30px;
		}

		& > *:first-child {
			margin-top: 0;
		}
	}

	&__image {
		height: 120px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
	}

	&__selected {
		position: absolute;
		top: 50%;
		font-size: 12px;
		font-weight: 700;
		color: #a8baca;
		transform: translateX(-50%) rotate(-90deg);
		left: 30px;
		transform-origin: 50% 50%;
		margin-top: -7px;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: top;
			margin: 3px 7px 0 0;
			width: 8px;
			height: 8px;
			border-radius: 4px;
			background: #656262;
		}
	}
}



input::-webkit-contacts-auto-fill-button {
	visibility: hidden;
	display: none !important;
	pointer-events: none;
	position: absolute;
	right: 0;
  }

.fade-in {
	opacity: 0;
	transform: translateY(40px);
	transition: opacity 500ms $ease-in-out-quad, transform 500ms $ease-in-out-quad;

	&.animate {
		opacity: 1;
		transform: none;
	}
}

.nav-bar-border {
	display: none;

	@include media-query(from laptop) {
		position: fixed;
		display: block;
		z-index: 9;
		top: 0;
		left: 10%;
		height: 100%;
		width: 1.8vw;
		background: #E7E5E2;
		transition: left 300ms $ease-in-out-quad;

		&.is-open {
			 left: 260px;
		}
	}
}

.video-wrap {
	@include aspect-ratio(576/324);
	margin-top: 40px;

	@include media-query(from tablet) {
		margin-top: 0;
	}
}

.imagelinks {
	&__text {
		padding-bottom: 30px;

		@include media-query(from laptop) {
			padding-bottom: 60px;
		}
	}

	.button--image-link {
		margin-bottom: 2.5vw;
	}

	.grid-product & {
		.button--image-link {
			margin-bottom: 1.8vw;
		}
	}
}

.text-block {
	padding: 0 2.5vw;


	.grid-product & {
		padding: 0 1.8vw;
	}

	& > *:first-child {
		margin-top: 0;
	}

	@include media-query(from laptop) {
		padding: 0 1.25vw;

		.grid-product & {
			padding: 0 0.9vw;
		}
	}

	&__section {
		margin: 60px 0 0;

		&:first-child {
			margin-top: 0;
		}

		& > *:first-child {
			margin-top: 0;
		}

	/*	& > .title:first-child {
			margin-top: -5px;
		}*/
	}
}

.contacts-list {
	padding-top: 60px;
}

.images-list {
	img {
		max-width: 100%;
		margin: 20px 20px 0px 0;

		@include media-query(from tablet7) {
			margin: 0 35px 40px 35px;
		}
	}
}

.text-image {
	&__image {
		margin: 0 0 40px;

		@include media-query(from tablet7) {
			margin: 0;
		}
	}
	&__text + .text-image__image {
		margin: 40px 0 0;

		@include media-query(from tablet7) {
			margin: 0;
		}
	}

	& + .text-image {
		margin-top: 40px;

		@include media-query(from tablet7) {
			margin: 0;
		}
	}
}

.options-list {
	margin: 0 0 -30px;
	font-size: 16px;
	color: #353535;
	letter-spacing: −0.11px;
	line-height: 24px;
	font-weight: 300;
	display: flex;
	flex-wrap: wrap;

	&__item {
		overflow: hidden;
		margin: 0 0 30px;
		position: relative;
		min-height: 120px;
		min-width: 280px;
		flex-basis: 0;
		flex-grow: 1;

		@include media-query(from tablet7) {
			flex-basis: 50%;
		}
		@include media-query(from tablet7) {
			flex-basis: 33%;
			flex-grow: 0;
		}
	}

	&__image {
		position: absolute;
		width: 120px;
		height: auto;
		left: 0;
		top: 0;
	}

	&__text {
		padding: 0 20px 0 140px;
		max-width: 480px;

		& > *:first-child {
			margin-top: 0;
		}

		@include media-query(from tablet) {
			padding: 0 40px 0 140px;
		}
	}

	&__title {
		margin: 0 0 -4px;
		font-size: 18px;
		line-height: 28px;
		color: #6A6A6A;
		font-weight: 300;
	}

	p {
		margin-bottom: 0;
	}

	.text {
		padding: 0 40px 0 0;
	}
}

.breadcrumbs {
	padding: 20px 0;
	font-weight: 300px;
	font-size: 11px;
	color: #696969;
	letter-spacing: 2px;
	line-height: 14px;
	text-align: center;
	text-transform: uppercase;

	&__subtitle {
		display: none;

		opacity: 0;
		max-width: 0;
		overflow: hidden;
		transition: max-width 300ms, opacity 300ms;

		@include media-query(from laptop) {
			display: inline-block;
		}

		.show-title & {
			opacity: 1;
			max-width: 300px;
		}
	}
	//&.is-fixed {
		@include media-query(from laptop) {
			position: fixed;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 9;
			top: 0;
			left: 0;
			right: 0;
			padding: 0 0 0 10%;
			height: 70px;
			background: #E7E5E2;
		}
	//}
/*
	@include media-query(from laptop) {
		padding: 43px 0;
	}*/
}

.page-title {
	text-align: center;
	padding: 23px 0 40px;
	font-size: 14px;

	@include media-query(from laptop) {
		padding: 53px 0 73px;
	}

	&__title {
		margin: 0;
		font-size: 30px;

		@include media-query(from laptop) {
			margin: 0 0 5px;
			font-size: 48px;
		}
	}

	&__subtitle {
		line-height: 1.28em;
		margin: 0;
		padding: 0 50px;
	}
}

.page-anchors {
	height: 26px;
	overflow-x: auto;
	white-space: nowrap;

	.page-title + & {
		margin-top: -30px;

		@include media-query(from laptop) {
			margin-top: -33px;
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
	}

	&__item {
		display: inline-block;
		vertical-align: top;
	}

	&__link {
		display: inline-block;
		vertical-align: top;
		margin: 0 13px;
		font-weight: 800;
		font-size: 11px;
		color: #353535;
		letter-spacing: 2px;
		line-height: 14px;
		text-transform: uppercase;

		&.is-active,
		&:hover {
			color: #696969;

			&:after {
				content: '';
				margin: 6px auto 0;
				display: block;
				width: 17px;
				height: 2px;
				overflow: hidden;
				background: #00C4FB;
			}
		}
	}
}

.product-title {
	padding: 40px 0 40px;
	font-size: 14px;

	@include media-query(from laptop) {
		padding: 80px 0 80px;
	}

	.title {
		margin-bottom: 10px;
	}
	/*.title {
		margin: 0 0 5px;
		font-size: 30px;

		@include media-query(from laptop) {
			margin: 0 0 19px;
			font-size: 48px;
		}
	}*/

	p {
		line-height: 1.28em;
		margin: 0;
		padding: 0 50px;
	}
}

.section-title {
	.title--caps {
		margin: 0 0 17px;
	}
	.title--caps + .title {
		margin-top: 0;
	}
}

.shipping {
	overflow: hidden;
	padding: 50px 0 0;

	@include media-query(from tablet) {
		padding-top: 0;
	}

	.icon--worldwide {
		float: left;
		margin: 6px 13px 0 0;
	}

	.text {
		float: left;
	}

	.title {
		margin-bottom: -4px;
	}

	a {
		font-size: 14px;
		color: #00A4D2;

		&:hover {
			text-decoration: underline;
		}
	}
}

.newsletter-form {
	position: relative;
	display: block;
	padding: 0;
	margin: 0 -10px;

	@include media-query(from laptop) {
		margin: 0 -20px;
	}

	&__input {
		display: block;
		box-sizing: border-box;
		padding: 0 44px 13px 10px;
		width: 100%;
		font-size: 14px;
		font-weight: 300;
		color: #353535;
		letter-spacing: −0.11px;
		line-height: 28px;
		border: none;
		background: none;
		border-bottom: 1px solid #fff;

		@include media-query(from laptop) {
			padding-left: 20px;
			padding-right: 54px;
		}

		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: #353535;
			opacity: 1; /* Firefox */
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #353535;
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
			color: #353535;
		}
	}

	&__submit {
		position: absolute;
		right: 4px;
		top: 1px;
		border: none;
		background: transparent;

		@include media-query(from laptop) {
			right: 14px;
		}
	}

	span.error {
		color: #c00;
		font-size: 14px;
		font-weight: 300;
		padding: 10px 0 0 20px;
		display: block;

	}
}

.newsletter-form-success {
	font-weight: 300;
}
/*
.overlay {
	position: fixed;
	z-index: 8;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
	opacity: 0;
	background: #fff;
	transition: opacity 300ms $ease-in-out-quad, visibility 0ms 300ms;

	&.is-visible {
		visibility: visible;
		opacity: .5;
		transition: opacity 300ms $ease-in-out-quad, visibility 0ms;
	}
}*/

.steps-mobile {
	margin-top: 40px;

	&__images {
		position: relative;
		padding-bottom: 67%;
		background: #fff;
	}

	&__images-item {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		opacity: 0;
		transition: opacity 300ms;

		&.is-active {
			opacity: 1;
		}
	}

	&__image {
		margin: 0 auto;
		width: 40%;
		max-width: 150px;
		padding: 0; /*10px 25px;*/
		background: #fff;
		height: 90.2vw;
		overflow: hidden;
		transform: rotate(-90deg);
		margin-top: -25vw;
		margin-bottom: -32vw;

		img {
			position: relative;
			display: block;
			margin: 0 auto;
			width: 80%;
			height: auto;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__slider {
		overflow: hidden;
		/*margin-top: -50%;*/
	}

	&__content {
		text-align: center;
	}

	.title {
		margin-top: 20px;
		font-size: 28px;
		line-height: 40px;
	}

	.text {
		padding: 0 11%;
	}

	.slick-prev, .slick-next {
		z-index: 20;
		overflow: hidden;
		text-indent: 100%;
		@include fluid-type(top, 320px, 768px, 150px, 300px);

	}

	.slick-prev {
		left: 17px;
		background: url(../images/icon-arrow-left-dark.svg) no-repeat 0 0;
		background-size: contain;
	}

	.slick-next {
		right: 17px;
		background: url(../images/icon-arrow-right.svg) no-repeat 0 0;
		background-size: contain;
	}
}

.steps-module {
	margin-top: 70px;

	&__step {
		position: relative;
		min-height: 100vh;
	}

	&__content {
		width: 50%;
		padding: 50px 0;

		img {
			max-height: 420px;
		}
	}

	&__visual {
		width: 50%;
		position: absolute;
		z-index: 5;
		right: 0;
		top: 0;
		opacity: 0;
		height: 100vh;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		transition: opacity 300ms;

		.is-fixed & {
			position: fixed;
			right: 0;
			top: 0;
		}

		&.is-visible {
			opacity: 1;
		}

	}

	&__step:last-child {
		.steps-module__visual {
			z-index: 3;
			top: auto;
			bottom: 0;
			opacity: 1 !important;
		}
	}

}


.scroll-section {
	&__fixed {
		position: absolute;
		overflow: hidden;
		max-height: 100vh;

		&.is-fixed {
			position: fixed;
			top: 0;
		}

		img {
			//max-height: 100vh;
		}
	}
}

*:focus {
	outline: none;
}
.no-scroll {
	overflow: hidden;
}
.no-transition {
	transition: none !important;
}



.logo-bolefloor {
	display: block;
	width: 33px;
	height: 7px;
	margin: 0 auto;
	background: url(../images/logo-bole-text.svg) no-repeat 0 0;
	background-size: contain;
	overflow: hidden;
	white-space: nowrap;
	text-indent: 100%;
}

.process-step {
	position: relative;

	&__number {
		position: absolute;
		left: 5px;
		margin-top: 20px;
		width: 38px;
		height: 38px;
		font-size: 20px;
		line-height: 34px;
		color: #6A6A6A;
		font-weight: 300;
		text-align: center;
		border: 1px solid #C9C6C0;
		border-radius: 50%;

		@include media-query(from tablet7) {
			position: static;
			margin-top: 0;
		}

		@include media-query(from tablet) {
			position: absolute;
			left: 0;
			top: 15px;
			width: 48px;
			height: 48px;
			font-size: 28px;
			line-height: 44px;
			margin-left: 10px;
		}

		@include media-query(from laptop) {
			margin-left: 20px;
		}
	}

	&__image {
		max-height: 260px;
		overflow: hidden;
	}

	&--right {
		.process-step__number {
			@include media-query(from tablet) {
				left: 50%;
			}
		}
	}

	.title {
		margin-left: 60px;

		@include media-query(from tablet7) {
			margin-left: 0;
			margin-top: 15px;
		}
	}
}

.gallery-modal {
	background: #fff;
	overflow-y: auto;
	z-index: 11;

	@include media-query(from laptop) {
		left: 10%;
		z-index: 10;
	}

	.modal {
		display: block;
		background: #fff;
		width: 100%;
		min-height: 100%;
		margin: 0;
		padding: 10px;

		@include media-query(from laptop) {
			padding: 2.5vw;
		}
	}

	.col {
		position: relative;
	}

	&__full-wrap {
		position: relative;
		padding-bottom: 120%;

		@include media-query(from laptop) {
			padding: 0;
			height: calc(100vh - 2.7vw - 2.7vw);
		}
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-position: 50% 100%;
		background-size: contain;
		background-repeat: no-repeat;

		@include media-query(from laptop) {
			background-position: 100% 100%;
		}
	}

	&__meta {
		position: relative;
		margin: 17px 0 5px;

		@include media-query(from tablet) {
			margin-top: 27px;
		}

		@include media-query(from laptop) {
			position: absolute;
			margin: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__count {
		position: absolute;
		right: 10px;
		top: 0;

		@include media-query(from laptop) {
			position: static;
			margin: 14px 0;
		}
	}
	&__category {

	}

	&__title {
		margin: 0;
		font-weight: 300;
		font-size: 28px;
		color: #6A6A6A;
		letter-spacing: −0.4px;
		line-height: 36px;
	}

	&__actions {
		margin: 20px 0 0;
		position: relative;
		border: 1px solid #DDDBD4;

		@include media-query(from tablet) {
			margin: 30px 0 0;
			text-align: center;
		}

		@include media-query(from laptop) {
			position: absolute;
			bottom: 0;
			right: 1.35vw;
			left: 1.35vw;
		}
	}

	&__prev,
	&__next {
		display: block;
		position: absolute;
		top: 16px;
		width: 24px;
		height: 17px;
		margin: 0;
		padding: 0;
		border: none;
		overflow: hidden;
		white-space: nowrap;
		text-indent: 100%;
	}
	&__prev {
		left: 20px;
		background: url(../images/icon-modal-prev.svg) no-repeat 0 0;
		background-size: contain;
	}

	&__next {
		right: 20px;
		background: url(../images/icon-modal-next.svg) no-repeat 0 0;
		background-size: contain;
	}

	&__close {
		display: block;
		width: 24px;
		height: 24px;
		position: static;
		margin: 13px auto;
		padding: 0;
		border: none;
		overflow: hidden;
		white-space: nowrap;
		text-indent: 100%;
		background: url(../images/icon-close.svg) no-repeat 0 0;
		background-size: contain;

		@include media-query(from laptop) {
			position: absolute;
			z-index: 15;
			margin: 0;
			right: 2.7vw;
			top: 2.7vw;
		}
	}
}

.cv-modal {
	background: #fff;
	overflow-y: auto;
	z-index: 11;
	top: 60px;

	@include media-query(from laptop) {
		left: 10%;
		top: 0;
		z-index: 8;
	}

	.modal {
		display: block;
		background: #fff;
		width: 100%;
		min-height: 100%;
		margin: 0;
		padding: 10px;

		@include media-query(from laptop) {
			padding: 2.5vw;
			padding-top: 120px;
		}
	}

	&__close {
		display: block;
		width: 24px;
		height: 24px;
		position: absolute;
		right: 2.5vw;
			top: 2.5vw;
		z-index: 15;
		margin: 0;
		padding: 0;
		border: none;
		overflow: hidden;
		white-space: nowrap;
		text-indent: 100%;
		background: url(../images/icon-close.svg) no-repeat 0 0;
		background-size: contain;
	}
}

.gallery-text {
	/*.title {
		font-size: 21px;
		line-height: 1.16em;

		@include media-query(from laptop) {
			font-size: 36px;
		}
	}*/
	.text-block.text {
		padding-bottom: 30px;
	}
}

.gallery-filter {
	background: #F3F2F0;
	position: relative;
	margin: 15px 0 5px;

	@include media-query(from tablet) {
		overflow: hidden;
	}

	@include media-query(from laptop) {
		margin: 0 0 1.35vw;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 16px;
		right: 20px;
		width: 16px;
		height: 9px;
		background: url(../images/icon-arrow-toggle-dark.svg) no-repeat 0 0;
		background-size: contain;
		transition: 300ms transform;

		@include media-query(from tablet) {
			display: none;
		}
	}

	&.is-open:after {
		transform: rotate(180deg);
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		max-height: 0;
		overflow: hidden;
		transition: 300ms max-height;

		.is-open &,
		&.is-active {
			max-height: 60px;
		}

		@include media-query(from tablet) {
			float: left;
			display: block;
			max-height: 100%;

			&:first-child {
				border-right: 1px solid #fff;
			}
		}
	}

	&__link {
		display: inline-block;
		padding: 13px 20px;
		font-weight: 600;
		font-size: 11px;
		color: #696969;
		letter-spacing: 2px;
		line-height: 14px;
		text-transform: uppercase;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: top;
			margin: 6px 18px 0 0;
			width: 9px;
			height: 2px;
			background: #D8D6D3;
			transition: width 300ms, background 300ms;
		}

		&.is-active {
			&:before {
				width: 18px;
				background: #00C4FB;
			}
		}

		/*.gallery-filter__item:first-child &:before {
			width: 18px;
		}*/
	}
}

.gallery-more {
	padding: 35px 0 0;
	text-align: center;

	@include media-query(from tablet) {
		text-align: left;
	}
}

.gallery-block {
	img {
		display: block;
		width: 100%;
		height: auto;
		position: absolute;
		left: 0;
		top: 0;
	}
	.col {
		position: relative;
	}
	.vertical-flex {
		display: flex;
		flex-direction: column;
	}
	.row-item {
		padding: 2.7vw 0;
		position: relative;

		&:first-child {
			padding-top: 0;

			.item {
				bottom: 2.7vw;
			}
		}
		&:last-child {
			padding-bottom: 0;

			.item {
				top: 2.7vw;
			}
		}
	}
	.item {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-repeat: no-repeat;
		background-position: 50% 100%;
		background-size: cover;
	}
	.item-h {
		left: 2.7vw;
		right: 2.7vw;
	}
	.item-1 {
		display: block;
		width: 100%;
		position: relative;
		padding-bottom: 65.714%;
	}
	.item-2 {
		display: block;
		width: 100%;
		position: relative;
		padding-bottom: 143.571%;
	}
	.item-6 {
		display: block;
		width: 100%;
		position: relative;
		padding-bottom: 67.676%;
	}
}

.media-filter {
	background: #F3F2F0;
	position: relative;
	margin: 15px 0 5px;

	@include clearfix();

	&__filter {
		position: relative;
		float: left;
		padding: 0 40px 0 0;
		width: 50%;
		text-overflow: ellipsis;

		&:first-child {
			border-right: 1px solid #fff;
		}

		&--time {
			float: right;

			@include media-query(from laptop) {
				width: auto;
				border-left: 1px solid #fff;
			}
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			z-index: 7;
			top: 16px;
			right: 20px;
			width: 16px;
			height: 9px;
			background: url(../images/icon-arrow-toggle-dark.svg) no-repeat 0 0;
			background-size: contain;
			transition: 300ms transform;
		}

		&.is-open:after {
			transform: rotate(180deg);
		}

		&--category {
			&:after {
				@include media-query(from laptop) {
					display: none;
				}
			}

			@include media-query(from laptop) {
				width: auto;
			}
		}
	}

	&__item {
		&.is-active {
			display: none;
		}

		.media-filter__dropdown--category & {
			@include media-query(from laptop) {
				display: inline-block;

				&:first-child {
					border-right: 1px solid #fff;
				}
			}
		}
	}
	&__selected {
		font-weight: 600;
		font-size: 11px;
		letter-spacing: 2px;
		color: #696969;
		padding: 13px 0px 13px 20px;
		text-transform: uppercase;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@include media-query(from laptop) {
			padding: 13px 20px;
		}

		&--category {
			@include media-query(from laptop) {
				display: none;
			}
		}
	}

	&__dropdown {
		display: none;
		position: absolute;
		z-index: 6;
		list-style: none;
		margin: 0;
		padding: 0;
		top: 100%;
		left: 0;
		min-width: 100%;

		background: #F3F2F0;

		&--right {
			right: 0;
			left: auto;
		}

		&--category {
			@include media-query(from laptop) {
				font-size: 0;
				display: inline-block;
				white-space: nowrap;
			}
		}

		.is-open & {
			display: block;
		}
	}

	&__link {
		display: block;
		padding: 13px 20px;
		font-weight: 600;
		font-size: 11px;
		color: #696969;
		letter-spacing: 2px;
		line-height: 14px;
		text-transform: uppercase;
		white-space: nowrap;

		&:hover {
			color: #353535;
			background: #EDEBE8;
		}

		.media-filter__dropdown--category & {
			@include media-query(from laptop) {
				&:before {
					content: '';
					display: inline-block;
					vertical-align: top;
					margin: 6px 18px 0 0;
					width: 9px;
					height: 2px;
					background: #D8D6D3;
					transition: width 300ms, background 300ms;
				}

			}
		}

		.media-filter__dropdown--category .is-active &:before {
			@include media-query(from laptop) {
				width: 18px;
				background: #00C4FB;
			}
		}
	}
}



.article {
	&__date {
		font-weight: 800;
		font-size: 11px;
		color: #696969;
		letter-spacing: 2px;
		line-height: 14px;
		text-transform: uppercase;
	}

	&__title {
		font-weight: 300;
		font-size: 21px;
		color: #00A4D2;
		letter-spacing: −0.14px;
		line-height: 30px;
		margin: 10px 0 0;
	}

	&__dates {
		margin: 12px 0 0;
		font-weight: 300;
		font-size: 16px;
		color: #353535;
		letter-spacing: −0.11px;
		line-height: 24px;
		padding: 0;
		white-space: nowrap;

		&--soon {
			padding-left: 10px;
			border-left: 2px solid #00D969;
		}
		&--now {
			padding-left: 10px;
			border-left: 2px solid #FF653C;
		}
	}

	&__dates-soon,
	&__dates-now {
		font-weight: 800;
		font-size: 11px;
		color: #00D969;
		letter-spacing: 2px;
		line-height: 14px;
		white-space: nowrap;
		text-transform: uppercase;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: top;
			margin: 13px 5px 0 0;
			width: 27px;
			height: 1px;
			overflow: hidden;
			background: #00D969;
		}
	}

	&__dates-now {
		color: #FF653C;

		&:before {
			background: #FF653C;
		}
	}

	&__intro {
		margin: 11px 0 0;
		font-weight: 300;
		font-size: 16px;
		color: #353535;
		letter-spacing: −0.11px;
		line-height: 28px;
	}

	&__image-container {
		margin: 35px 25% 0 0;
		overflow: hidden;

		&--last {
			margin: 0;
		}
	}
	&__image-wrap {
		position: relative;
		padding-bottom: 78%;
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		z-index: 5;

		&:nth-child(2) {
			z-index: 3;
			left: auto;
			right: 0;
		}

		.article__image-wrap--magazine &:nth-child(1) {
			box-shadow: 5px 0 0 0 rgba(0,0,0,0.48);
		}
	}

	&--last {
		.article__title {
			font-size: 36px;
			letter-spacing: −0.51px;
			line-height: 42px;
		}
		.article__dates {
			color: #6A6A6A;
			font-size: 21px;
			line-height: 36px;
		}
		.article__intro {
			color: #6A6A6A;
			font-size: 21px;
			line-height: 36px;
		}

		.article__dates-soon,
		.article__dates-now {
			&:before {
				margin-top: 21px;
			}
		}
	}

	&--list {
		padding: 40px 0 0;

		.article__title,
		.article__intro {

			@include media-query(from laptop) {
				padding-right: 25%;
			}
		}

		@include media-query(from tablet) {
			padding: 40px 0 30px;
		}

		@include media-query(from laptop) {
			padding: 60px 0 35px;
		}
	}

	&__images {
		text-align: center;

		img {
			margin-bottom: 60px;
		}

		&--magazine {
			padding: 0 8%;
		}
	}

	&--full {
		.article__title {
			font-size: 36px;
			letter-spacing: −0.51px;
			line-height: 42px;
			color: #696969;
		}
	}
}

.events-grid {
	&__load-more {
		margin-top: 30px;
	}

	.col {
		display: none;
	}
	.is-active {
		display: block;

		&.is-hidden {
			display: none;
		}
	}

}

.upcoming-events {
	position: relative;
	padding: 12px 18px 87px;
	background: #F3F2F0;
	overflow: hidden;

	&__title {
		margin: 0 0 66px;
		font-weight: 300;
		font-size: 28px;
		color: #6A6A6A;
		letter-spacing: −0.4px;
		line-height: 36px;
	}

	&__all {
		display: none;
		position: absolute;
		top: 30px;
		right: 23px;
		font-weight: 600;
		font-size: 11px;
		color: #696969;
		letter-spacing: 2px;
		line-height: 14px;
		text-transform: uppercase;

		@include media-query(from desktop) {
			display: block;
		}
	}

	.article {
		opacity: 0;
		position: absolute;
		top: 57px;
		left: 18px;
		right: 18px;
		transition: opacity 300ms;

		&.is-active {
			opacity: 1;
		}
	}

	.article__title {
		display: block;
		margin: 10px 0 0;
	}

	&__prev,
	&__next {
		display: block;
		position: absolute;
		bottom: 19px;
		left: 18px;
		width: 26px;
		height: 19px;
		white-space: nowrap;
		text-indent: 100%;
		overflow: hidden;
		background: url(../images/icon-arrow-left-dark.svg) no-repeat 0 0;
		background-size: contain;
	}

	&__next {
		left: auto;
		right: 18px;
		background: url(../images/icon-arrow-right.svg) no-repeat 100% 0;
		background-size: contain;
	}
	&__pager {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		font-weight: 800;
		font-size: 11px;
		color: #696969;
		letter-spacing: 2px;
		line-height: 14px;
	}
}

.max-100 {
	max-width: 100%;
}

.max--certificate {
	max-height: 100px;
}

.is-hidden {
	display: none;
}



/*
.steps-mobile {
	margin-top: 40px;

	&__images {
		position: relative;
		padding-bottom: 67%;
		background: #fff;
	}

	&__images-item {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		opacity: 0;
		transition: opacity 300ms;

		&.is-active {
			opacity: 1;
		}
	}

	&__image {
		margin: 0 auto;
		width: 30%;
		max-width: 150px;
		padding: 10px 25px;
		background: #fff;

		img {
			display: block;
			margin: 0 auto;
			width: 100%;
			height: auto;
		}
	}

	&__slider {
		overflow: hidden;
		margin-top: -50%;
	}

	&__content {
		text-align: center;
	}

	.title {
		margin-top: 20px;
		font-size: 28px;
	}

	.text {
		padding: 0 25px;
	}
}
*/
