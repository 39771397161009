@mixin aspect-ratio($ratio: 16/9, $position: relative, $descendant: "*") {
  display: block;
  height: 0; 
  position: $position;
  padding-bottom: percentage(1 / $ratio);

  > #{$descendant} {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; 
    
  }
}
