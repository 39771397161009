.icon {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;

	&--small {
		width: 16px;
		height: 16px;
	}
	&--medium {
		width: 32px;
		height: 32px;
	}
	&--large {
		width: 64px;
		height: 64px;
	}

	&--close {
		width: 24px;
		height: 24px;
		background: url(../images/icon-close.svg) no-repeat 0 0;
		background-size: contain;
	}
	&--close-white {
		width: 15px;
		height: 15px;
		background: url(../images/icon-close-white.svg) no-repeat 0 0;
		background-size: contain;
	}


	&--arrow-right {
		width: 16px;
		height: 32px;
		background: url(../images/icon-arrow-right.svg) no-repeat 0 50%;
		background-size: contain;
	}
	&--arrow-left {
		width: 16px;
		height: 32px;
		background: url(../images/icon-arrow-left.svg) no-repeat 0 50%;
		background-size: contain;
	}
	

	&--arrow-bottom {
		width: 32px;
		height: 16px;
		background: url(../images/icon-arrow-bottom.svg) no-repeat 0 50%;
		background-size: contain;
	}

	&--facebook-diamond {
		width: 52px;
		height: 52px;
		background: url(../images/icon-facebook-diamond.svg) no-repeat 0 50%;
		background-size: contain;
	}

	&--instagram-diamond {
		width: 52px;
		height: 52px;
		background: url(../images/icon-instagram-diamond.svg) no-repeat 0 50%;
		background-size: contain;
	}




	&--lightning {
		width: 29px;
		height: 20px;
		background: url(../images/icon-lightning.svg) no-repeat 0 50%;
		background-size: contain;
	}
	&--usb {
		width: 34px;
		height: 22px;
		background: url(../images/icon-usb.svg) no-repeat 0 50%;
		background-size: contain;
	}

	

	&--twitter {
		width: 22px;
		height: 22px;
		background: url(../images/icon-twitter.svg) no-repeat 0 50%;
		background-size: contain;
	}
	&--youtube {
		width: 22px;
		height: 22px;
		background: url(../images/icon-youtube.svg) no-repeat 0 50%;
		background-size: contain;
	}
	&--appstore {
		width: 133px;
		height: 47px;
		background: url(../images/icon-app-store.svg) no-repeat 0 50%;
		background-size: contain;

		@include media-query(from largephone) {
			width: 159px;
			height: 57px;
		}

		@include media-query(from tablet) {
			width: 133px;
			height: 47px;
		}

		@include media-query(from laptop) {
			width: 159px;
			height: 57px;
		}
	}
	&--googleplay {
		width: 139px;
		height: 47px;
		background: url(../images/icon-google-play.svg) no-repeat 0 50%;
		background-size: contain;

		@include media-query(from largephone) {
			width: 168px;
			height: 57px;
		}

		@include media-query(from tablet) {
			width: 139px;
			height: 47px;
		}

		@include media-query(from laptop) {
			width: 168px;
			height: 57px;
		}
		
	}
}

.icon-before {

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 16px;
		height: 16px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
	}

	&--small:before {
		width: 16px;
		height: 16px;
	}
	&--medium:before {
		width: 32px;
		height: 32px;
	}
	&--large:before {
		width: 64px;
		height: 64px;
	}

	&--facebook:before {
		background-image: url(../images/icon-facebook.svg);
	}

	&--twitter:before {
		background-image: url(../images/icon-twitter.svg);
	}

	&--youtube:before {
		background-image: url(../images/icon-youtube.svg);
	}
}