.text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

    @include media-query(from laptop) {
        font-size: 18px;
        line-height: 26px;
    }

    a {
        text-decoration: underline;
    }
    
    h1,h2,h3,h4,h5,h6,p,ul,ol,quote,blockquote {
        margin: 0;
        padding: 12px 0;

        @include media-query(from laptop) {
            padding: 13px 0;
        }
    }

    h1 + p,
    h2 + p,
    h3 + p,
    h4 + p,
    h5 + p,
    h6 + p {
        padding-top: 7px;

        @include media-query(from laptop) {
            padding-top: 0px;
        }
    }
    
    .title--main {
        margin-bottom: 5px;

        @include media-query(from tablet) {
            margin-bottom: 35px;
        }
    }

/*
    ul, ol {
        padding-left: 20px;
    }

    & > *:first-child {
        margin-top: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    h1,h2,h3,h4,h5,h6,p,ul,ol,quote,blockquote {
        margin-top: 25px;
        margin-bottom: 25px;

        @include media-query(from laptop) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    img {
        display: block;

        &.img-100 {
            width: 100%;
            height: auto;
        }
    }

    img + p {
        margin-top: 15px;
    }

    .align-left {
        float: left;
        vertical-align: top;
        margin-right: 20px;
    }

    .align-right {
        float: left;
        vertical-align: top;
        margin-left: 20px;
    }

    .no-spacing {
        margin: 0;	
    }
    
    &--footer {
        font-size: 16px;
        color: #fff;
        line-height: 1.875em;
        padding: 50px 0 27px;
    }
    */

    &--center {
        text-align: center;
    }
    &--right {
        text-align: right;
    }
    &--left {
        text-align: left;
    }

    @each $device in $devices {
        $key: nth($device, 1);
        $value: nth($device, 2);
        $minwidth: nth(nth($value, 1), 2);
        
        @media screen and (min-width: #{$minwidth}) {
          &--#{$key} {
            &-left {
              text-align: left;
            }
            &-right {
                text-align: left;
            }
            &-center {
                text-align: center;
            }
          }
        }
    }
}
