.button {
	display: inline-block;
	position: relative;
	max-width: 100%;
	border-radius: 0;
	white-space: nowrap;
	background: none;

	&--arrow {
		position: relative;
		text-decoration: none;

		.button__title {
			font-size: 18px;
			line-height: 26px;
			border-bottom: 2px solid #000;
			display: inline-block;
			padding-bottom: 1px;
			letter-spacing: 1px;
			opacity: 1;
			transition: opacity 100ms $ease-out-cubic;
			text-transform: uppercase;

			@include media-query(from laptop) {
				font-size: 24px;
				text-transform: none;
				padding-bottom: 4px;
				padding-top: 8px;
				letter-spacing: 0;
			}
		}
		.button__hover {
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			top: 13px;
			overflow: hidden;

			&--right {
				text-align: right;

				.icon {
					float: right;
				}
			}
		}

		.button__arrow-wrap {
			width: 320px;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
		}

		.icon {
			height: 26px;
			opacity: 0;
			transition: opacity 300ms $ease-out-cubic;

			@for $i from 1 through 21 {
				&:nth-child(#{$i + 1}) {
					transition: opacity 300ms ($i * 50ms) $ease-out-cubic;
				}
			}
		}

		&:hover {
			.button__title {
				opacity: 0;
			}
			.icon {
				opacity: 1;

				@for $i from 1 through 7 {
					&:nth-child($i) {
						transition: opacity 300ms ($i * 10)ms $ease-out-cubic;
					}
				}
			}
		}

	}

	&--inline-block,
	&--block {
		white-space: nowrap;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
		background: #DBE7F5;
		border: none;
		display: block;
		width: 100%;
		padding: 15px 0;

		@include media-query(from tablet) {
			font-size: 18px;
			padding: 18px 0;
		}
	}

	&--inline-block {
		display: inline-block;
		width: auto;
		padding-left: 30px;
		padding-right: 30px;
	}

	&--add {
		height: 65px;
		line-height: 65px;
		padding: 0 60px;
		color: #fff;
		font-size: 18px;
		font-weight: 500;
		background: #221f20;
		margin: 5px 0 30px 0;

		&:hover {
			color: #fff;
		}
	}
}
