.nav {
	&__toggle {
		position: fixed;
		z-index: 10;
		top: 15px;
		left: 20px;
		margin: 0;
		padding: 0;
		border: none;
		width: 26px;
		height: 20px;
		background: transparent;

		@include media-query(from tablet) {
			top: 50%;
			left: 32px;
			width: 46px;
			height: 30px;
			transform: translateY(-50%);
		}
	}

	&__toggle-line {
		display: block;
		width: 26px;
		height: 2px;
		position: absolute;
		left: 0px;
		top: 0px;
		background: #000000;

		@include media-query(from tablet) {
			width: 46px;
		}

		&:nth-child(1) {
			transform: translateY(0px);
		}
		&:nth-child(2) {
			width: 20px;
			transform: translateY(9px);

			@include media-query(from tablet) {
				width: 36px;
				transform: translateY(14px);
			}
		}
		&:nth-child(3) {
			transform: translateY(18px);

			@include media-query(from tablet) {
				transform: translateY(28px);
			}
		}

		.is-open & {

		}
	}


}

.nav--main {
	position: fixed;
	pointer-events: none;
	opacity: 0;
	z-index: 30;
	left: 0;
	top: 0;
	display: block;
	overflow: auto;
	background: #DBE7F5;
	color: #fff;
	height: 100%;
	width: 280px;
	transform: translateX(-100%);
	overflow: visible;
	transition: opacity 450ms 50ms $ease-out-cubic, transform 450ms 50ms $ease-out-cubic;

	@include media-query(from tablet) {
		width: auto;
		background: #DBE7F4;
		@media screen and (min-height: 800px) {
			background: none;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			/*left: 0;*/
			background: url(../images/bg-nav.svg) no-repeat 0 0;
			background-size: contain;
			width: 100vh;
			height: 100vh;
		}
	}
	&:after {
		@include media-query(from tablet) {
			right: -50vh;

			@media screen and (min-height: 800px) {
				right: auto;
				left: -50vh;
			}
		}
	}
	&.is-visible {
		pointer-events: all;
		opacity: 1;
		transform: translateX(0);
		transition: opacity 450ms $ease-out-cubic, transform 450ms $ease-out-cubic;

		.nav__items {
			opacity: 1;
			transform: translateX(0);
			transition: transform 450ms 250ms $ease-out-cubic, opacity 450ms 250ms $ease-out-cubic;
		}
	}

	.nav__close {
		position: fixed;
		z-index: 35;
		top: 15px;
		left: 20px;

		.icon {
			width: 20px;
			height: 17px;

			@include media-query(from tablet) {
				width: 32px;
				height: 29px;
			}
		}

		@include media-query(from tablet) {
			top: 50%;
			left: 32px;
			margin-top: -15px;
		}
	}

	.nav__items-wrap {
		position: relative;
		z-index: 3;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
	}

	.nav__items {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 100%;
		list-style: none;
		margin: 0;
		padding: 0;
		opacity: 0;
		transform: translateX(-100px);
		transition: transform 450ms $ease-out-cubic, opacity 450ms $ease-out-cubic;
	}

	.nav__item {
		text-align: left;
		padding: 0 49px 0 49px;
		margin-bottom: 10px;

		@include media-query(from tablet) {
			padding-left: 110px;
		}
	}

	.nav__link {
		display: inline-block;
		padding: 5px 0px;
		color: #090102;
		position: relative;
		font-size: 18px;

		@include media-query(from tablet) {
			font-size: 16px;
		}

		&.is-active {

		}
	}
}

.nav--footer {
	list-style: none;
	margin: 0 0 30px;
	padding: 0 0 33px;
	font-size: 16px;
	line-height: 1.625em;
	border-bottom: 1px solid #BCD1EB;

	@include media-query(from tablet) {
		border: none;
		margin: 0;
		padding: 0;
	}

	.nav__item {
		padding: 7px 0;

		@include media-query(from tablet) {
			padding: 3px 0;
		}
	}

	.nav__link {
		font-weight: 400;
	}
}

.nav--social {
	text-align: center;
	list-style: none;
	margin: 50px 0 0;
	padding: 0;
	font-size: 0;
	white-space: nowrap;

	@include media-query(from tablet) {
		text-align: right;
		margin: 115px 0 0;
	}

	.nav__item {
		display: inline-block;
		margin: 0 12px;
	}

	.nav__link {
		display: inline-block;
		padding: 10px 15px;
		font-size: 11px;
		color: #464646;
		letter-spacing: 2px;
		line-height: 14px;
		text-transform: uppercase;
	}
}
