.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    height: 50px;
    background: #fff;
    pointer-events: none;

    @include media-query(from tablet) {
        background: none;
    }

    body.admin-bar & {
      top: 32px;
    }

    &__logo {
        display: block;
		width: 156px;
        height: 21px;
        margin: 15px auto 0;
		white-space: nowrap;
		text-indent: 100%;
		overflow: hidden;
		background: url(../images/logo-nordhale.svg) no-repeat 0 0;
        background-size: contain;
        pointer-events: all;

        @include media-query(from tablet) {
            width: 222px;
            height: 30px;
            margin: 20px auto 0;
        }
    }



}
