html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

html,
input,
button,
textarea {
    font-family: $default_font;
}

html,
body {
    margin: 0;
    padding: 0;
    background: #fff;
    color: #090102;
}

input {
    -webkit-appearance: none;
    border-radius: 0;
    /* iphone safari border radius */
    &:focus {
        outline-style: none;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

button {
    cursor: pointer;
}

video,
img {
    border: none;
    /* IE9 images border */
}

a {
    color: #2a2a2a;

    text-decoration: none;

    &:hover,
    &:active {
        color: #686464;
        text-decoration: none;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}