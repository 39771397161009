.container {
	margin: 0 auto;
	max-width: 1460px;
	padding: 0 20px;

	@include media-query(from tablet) {
		padding: 0 104px;
	}

	@include media-query(from laptop) {
		padding: 0 110px;
	}

	&--hero {
		max-width: none;
	}
	/*
	@include media-query(from laptop) {
		max-width: 1500px;
		padding: 0 20px;
	}*/
}