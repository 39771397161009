$grid-column-count: 16;
$grid-gutter-width: 10px;

.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0;

	&--vertical {
		flex-direction: column;
	}

	&--wide {
		margin: 0 $grid-gutter-width * -1;
	}

	&--tablet-wide {
		@include media-query(from tablet) {
			margin: 0 $grid-gutter-width * -1;
		}
	}

	&--inner {
		margin: 0;
	}

	.row {
		//margin: 0 $grid-gutter-width * -1;

		&--inner {
			margin: 0;
		}
	}

	&--example {
		margin-bottom: 10px;

		.col {
			box-sizing: border-box;
			background: #ccc;
			border: 1px solid #000;
		}
	}

	&--vertical-center {
		align-items: center;
	}

	@each $device in $devices {
		$key: nth($device, 1);
		$value: nth($device, 2);
		$minwidth: nth(nth($value, 1), 2);

		@media screen and (min-width: #{$minwidth}) {
			&--#{$key}-vertical-center {
				align-items: center;
			}
		}
	}
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	padding: 0 ($grid-gutter-width);

	@for $i from 1 through $grid-column-count {
		&-#{$i} {
			flex: 0 0 percentage(1 / $grid-column-count * $i); /*width: percentage(1 / $grid-column-count * $i);*/
			max-width: percentage(1 / $grid-column-count * $i);
		}
	}

	@for $i from 1 through $grid-column-count {
		&-left-#{$i} {
			margin-left: percentage(1 / $grid-column-count * $i);
		}
		&-right-#{$i} {
			margin-right: percentage(1 / $grid-column-count * $i);
		}
	}

	&--no-gutter {
		padding: 0;
	}

	&--vertical-top {
		align-self: flex-start;
	}

	&--vertical-bottom {
		align-self: flex-end;
	}

	&--full {
		padding: 0;
	}

	&--vcenter {
		display: flex;
		align-items: center;
	}

	&-order-1 {
		order: 1;
	}
	&-order-2 {
		order: 2;
	}
	&-order-3 {
		order: 2;
	}
	&-order-4 {
		order: 2;
	}
}

@each $device in $devices {
	$key: nth($device, 1);
	$value: nth($device, 2);
	$minwidth: nth(nth($value, 1), 2);

	@media screen and (min-width: #{$minwidth}) {
		.col-#{$key} {
			&-auto {
				flex: 0 0 auto;
				width: auto;
				max-width: none;
			}

			&-order-1 {
				order: 1;
			}

			&-order-2 {
				order: 2;
			}
			&-order-3 {
				order: 2;
			}
			&-order-4 {
				order: 2;
			}

			&-order-initial {
				order: initial;
			}

			&-right-0 {
				margin-right: 0;
			}

			&-left-0 {
				margin-left: 0;
			}

			@for $i from 1 through $grid-column-count {
				&-#{$i} {
					flex: 0 0 percentage(1 / $grid-column-count * $i);/*width: percentage(1 / $grid-column-count * $i);*/
					max-width: percentage(1 / $grid-column-count * $i);
				}

				&-left-#{$i} {
					margin-left: percentage(1 / $grid-column-count * $i);
				}
				&-right-#{$i} {
					margin-right: percentage(1 / $grid-column-count * $i);
				}
			}
		}
	}
}
